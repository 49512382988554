


































































































import Vue from 'vue'
import Component from 'vue-class-component'

import { 
  isVictual as doIsVictual, 
  isRecipe as doIsRecipe
} from './model/shoppinglist'
import state from './state'

@Component({})
export default class Shoppinglist extends Vue {
  shoppingList = state.shoppingList

  isRecipe = doIsRecipe
  isVictual = doIsVictual
  isNotLastItem = (index: number): boolean => index + 1 < this.shoppingList.items.length

  async onPrintPreview (): Promise<void> {
    await this.$router.push('/shoppinglist/print')
  }
}
