



















































































































import Vue from 'vue'
import { Watch } from 'vue-property-decorator'
import Component from 'vue-class-component'
import RecipeApi from '../recipe/api/api'
import { recipeFirebaseApi } from '../recipe/api/firebaseApi'
import { 
  isVictual,
  isRecipe
} from './model/shoppinglist'
import state from './state'
import Recipe from './model/recipe'
import Victual from './model/victual'
import {
  getDepartmentOrder,
  defaultSupermarketDepartment
} from '../victual/model/victual'
import { groupConfigApi } from '@/config/api/firebaseApi'

interface VictualsWithDepartment {
  department: string
  victuals: Victual[]
}

@Component({
  filters: {
    currency: function (value: number): string {
      return value.toFixed(2) + ' €'
    }
  }
})
export default class ShoppintlistPrint extends Vue {
  recipeApi: RecipeApi = recipeFirebaseApi

  loading = false

  shoppingItems: VictualsWithDepartment[] = []
  totalPrice = 0

  printHeader = ''

  async mounted (): Promise<void> {
    await this.loadShoppinglistItems()
    await this.loadPrintHeader()
  }

  @Watch('shoppinglist', { deep: true })
  async shoppinglistStateChanged (): Promise<void> {
    await this.loadShoppinglistItems()
  }

  async loadShoppinglistItems (): Promise<void> {
    this.loading = true
    const victualsNotSorted = await this.getVictualsFromState()
    const victuals = victualsNotSorted.sort(this.compareByDepartment)
    this.replaceUndefinedDepWithDefault(victuals)

    this.shoppingItems = this.groupByDepartment(victuals)

    const totalPriceInCent = victuals
      .map(v => v.pricePer100g * v.amountInGrams)
      .reduce(this.getSum, 0)
    this.totalPrice = this.roundPointTwo(totalPriceInCent / 100)

    this.loading = false
  }

  async getVictualsFromState (): Promise<Victual[]> {
    const victuals = state.shoppingList.items
      .filter(e => isVictual(e))
      .map(e => e as Victual)

    const recipes = state.shoppingList.items
      .filter(e => isRecipe(e))
      .map(e => e as Recipe)

    for (const item of recipes) {
      const recipeVictuals = await this.extractVictuals(
        item.id,
        item.servingSize
      )
      victuals.push(...recipeVictuals)
    }

    return victuals
  }

  replaceUndefinedDepWithDefault (victuals: Victual[]): void {
    victuals.forEach(v => {
      v.supermarketDepartment =
        v.supermarketDepartment ?? defaultSupermarketDepartment
    })
  }

  async extractVictuals (
    recipeId: string,
    actualServingSize: number
  ): Promise<Victual[]> {
    const recipe = await this.recipeApi.get(recipeId)
    const amountScaleFactor = actualServingSize / recipe.servingSize

    const allIngredients = recipe.ingredientGroups.flatMap(e => e.ingredients)

    const victuals = allIngredients.map(e => ({
      id: e.victual.id,
      name: e.victual.name,
      supermarketDepartment: e.victual.supermarketDepartment,
      amountInGrams: e.amountInGrams * amountScaleFactor,
      pricePer100g: e.victual.pricePer100g,
      units: e.victual.units
    }))

    return victuals
  }

  groupByDepartment (victuals: Victual[]): VictualsWithDepartment[] {
    const allDeps = victuals.map(vic => vic.supermarketDepartment)
    const uniqueDeps = new Set(allDeps)

    const result: VictualsWithDepartment[] = []

    for (const dep of uniqueDeps) {
      const vics: Victual[] = []
      victuals
        .filter(v => v.supermarketDepartment === dep)
        .forEach(v => this.addCopyOrSumAmountOfVictual(vics, v))

      result.push({ department: dep as string, victuals: vics })
    }

    return result
  }

  addCopyOrSumAmountOfVictual (victuals: Victual[], victual: Victual): void {
    const found = victuals.find(x => x.id === victual.id)

    if (found === undefined) {
      victuals.push(Object.assign({}, victual))
      return
    }

    found.amountInGrams += victual.amountInGrams
  }

  compareByDepartment (a: Victual, b: Victual): number {
    const aOrder = getDepartmentOrder(a.supermarketDepartment)
    const bOrder = getDepartmentOrder(b.supermarketDepartment)

    return aOrder - bOrder
  }

  getFirstUnitAmount (vic: Victual): string {
    if (vic.units.length < 1) {
      return ''
    }
    
    const unit = vic.units[0]
    const unitAmount = this.roundPointTwo(vic.amountInGrams / unit.oneUnitEqualsGrams)
    if (unitAmount < 1) {
      return `< 1 ${unit.name}` 
    }

    return `${unitAmount} ${unit.name}`
  }

  roundPointTwo (value: number): number {
    return Math.round(value * 100) / 100
  }

  getItemPrice (item: Victual): number {
    return this.roundPointTwo((item.pricePer100g * item.amountInGrams) / 100)
  }

  getSum (total: number, num: number): number {
    return total + Math.round(num)
  }

  async loadPrintHeader (): Promise<void> {
    const group = await groupConfigApi.getGroupWithConfig()
    this.printHeader = group.config.printHeader ?? ''
  }

  onBack (): void {
    this.$router.go(-1)
  }

  onPrint (): void {
    window.print()
  }
}
